<template>
    <v-container>
        <confirm-modal
          :open="openModal"
          :close.sync="openModal"
        >
         <template #content>
            <span class="modal-content">
               You are going to delete this service. Take a look, this action cannot be reversed.
            </span>
            </template>
            <template #action>
              <v-btn
                depressed
                dense
                color="error lighten-1"
                class="mr-2"
                :loading="false"
                @click="confirmDelete()"
              >
                 Delete
              </v-btn>
            </template>
        </confirm-modal>
        <div class="d-flex my-5 align-center justify-space-between">
            <h2 class="primary--text main-heading">{{ $t(`Admin.service_box.title`) }}</h2>
            <v-divider class="mx-4" inset vertical> </v-divider>
            <v-spacer></v-spacer>
            <v-btn
                v-if="userIsAdmin"
                color="secondary primary--text"
                dark
                class="mb-2 pa-7"
                :to="createToolBox"
            >
                <v-icon small left color="black"> fa fa-plus </v-icon>
                {{ $t(`Admin.service_box.button_create`) }}
            </v-btn>
        </div>

        <v-row class="my-15">
            <v-col>
                <div class="d-flex align-center">
                    <v-icon normal left color="orange">
                        fa fa-star
                    </v-icon>
                    <h2 class="main-heading">{{ $t(`Admin.service_box.suggested_session_title`)}}</h2>
                </div>
                <base-card card-height="300">
                    <template #content>
                        <div
                         v-if="getSuggestedServicesStatus_Idle || getSuggestedServicesStatus_Pending"
                         class="d-md-flex"
                        >
                         <v-col
                            v-for="skeleton in 3"
                            :key="skeleton"
                         >
                            <v-skeleton-loader
                                type="card-heading, list-item-three-line, list-item-three-line, actions"
                            >
                            </v-skeleton-loader>
                         </v-col>
                        </div>
                        <div 
                         v-else-if="suggestedServices.length <= 0"
                         class="d-flex flex-column align-center"
                         >
                            <h2 class="mt-10 main-heading">There are no suggested services found</h2>
                            <p class="font-weight-light">You may come back later</p>
                        </div>
                        <tool-box-card
                         v-else
                         :card-background="true"
                         :services="suggestedServices" 
                         @delete-service="deleteService()"
                        >
                        </tool-box-card>    
                    </template>
                </base-card>
            </v-col>
        </v-row>

        <v-row 
          v-if="services.length > 0 || getServicesByFilterStatus_Success"
          class="mt-15 pt-15"
        >
            <v-col>
                <div class="d-flex align-center text-center">
                    <v-icon small left color="orange" class="hidden-sm-and-down">fa fa-exclamation</v-icon>
                    <h4 class="font-weight-light">
                        Use the search box to search for a service by <span class="font-weight-bold">Name</span> or use filters to search by <span class="font-weight-bold">Service Group, Content Type, Language</span>
                    </h4>
                </div>
            </v-col>
        </v-row>

        <v-row
          v-if="services.length > 0 || getServicesByFilterStatus_Success"
        >
            <v-col style="border-radius: 5px">
                <div class="d-flex align-center justify-end">
                    <v-icon small left color="purple">fa fa-filter</v-icon>
                    <span class="text-h6 font-weight-light">Filter by..</span>
                </div>
                <div class="d-flex align-center flex-wrap">
                    <template>
                        <v-select
                            v-model="selectedGroupType"
                            prepend-icon="fa fa-gear"
                            :items="serviceGroupList"
                            item-text="name"
                            item-value="id"
                            density
                            chips
                            deletable-chips
                            color="primary"
                            single-line
                            label="Service Group"
                            multiple
                        >
                        </v-select>
                        <v-select
                            v-model="selectedServiceType"
                            prepend-icon="fa fa-box"
                            :items="service_type"
                            single-line
                            density
                            deletable-chips
                            chips
                            color="primary"
                            label="Content Type"
                        >
                        </v-select>
                        <v-select
                            v-model="selectedLanguage"
                            prepend-icon="fa fa-language"
                            :items="serviceLanguageList"
                            item-text="name"
                            item-value="id"
                            signle-line
                            chips
                            deletable-chips
                            density
                            color="primary"
                            label="Language"
                            multiple
                        >
                        </v-select>
                    </template>
                </div>
                <div class="d-flex mt-5 justify-end">
                    <v-btn
                        depressed
                        outlined
                        color="primary"
                        :loading="getServicesByFilterStatus_Pending"
                        class="button-hover pa-3"
                        @click="fetchServicesByFilter()"
                    >
                     <v-icon left x-small>fa fa-search</v-icon>
                      Search
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row class="mb-10">
            <v-col>
                <div class="d-flex align-center">
                    <v-icon normal left color="purple">
                        fa fa-border-all
                    </v-icon>
                    <h2 class="main-heading">{{ $t(`Admin.service_box.allServices_session_title`)}}</h2>
                    <v-text-field
                          v-model="searchTitle"
                          style="max-width: 400px"
                          hide-details
                          label="Search by Name"
                          class="ml-10 pb-7"
                          @keyup.enter="fetchServicesByFilter()"
                        >
                            <template #append>
                                <v-btn
                                    depressed
                                    outlined
                                    color="primary"
                                    :loading="getServicesByFilterStatus_Pending"
                                    class="button-hover ma-2"
                                    @click="fetchServicesByFilter()"
                                >
                                    <v-icon
                                        x-small
                                        class="mr-2"
                                    >
                                        fa fa-search
                                    </v-icon>
                                    Search
                                </v-btn>
                            </template>
                    </v-text-field>
                </div>
                <div
                 v-if="getSuggestedServicesStatus_Idle || getSuggestedServicesStatus_Pending"
                 class="d-md-flex"
                >
                 <v-col
                  v-for="skeleton in 3"
                  :key="skeleton"
                 >
                  <v-skeleton-loader
                    type="card-heading, list-item-three-line, list-item-three-line, actions"
                  >
                  </v-skeleton-loader>
                 </v-col>
                </div>
                <div 
                  v-else-if="services.length <= 0"
                  class="d-flex flex-column align-center"
                >
                  <h2 class="mt-10 main-heading">There are no services found</h2>
                  <p class="font-weight-light">You may come back later</p>
                </div>
                <tool-box-card
                  v-else
                  :services="services"
                  @delete-service="deleteService()"
                >
                </tool-box-card>
                <div
                 v-if="pageCount > 1 && services.length > 0"
                 class="text-center mt-10"
                >
                    <v-pagination 
                     v-model="page"
                     :length="pageCount"
                     :total-visible="9"
                     :disabled="getAllServicesStatus_Pending"
                     elevation="0" 
                     rounded="circle"
                    >
                    </v-pagination>
                </div>
            </v-col>
        </v-row>
        
        
        <!-- Hide briefing section for now -->


        <!-- <v-row class="my-15">
            <v-col>
                <div class="d-flex align-center">
                    <v-icon normal left color="purple">
                        fa fa-square-rss
                    </v-icon>
                    <h2 class="main-heading">{{ $t(`Admin.service_box.briefing_session_title`)}}</h2>
                </div>
                <base-card card-height="300">
                    <template #content>
                        <div class="d-flex align-center flex-column">
                            <h1 class="main-heading">
                                Content TBA
                            </h1>
                            <v-img
                             src="../../assets/noData.svg"
                             width="250px"
                             height="300px"
                             contain
                            >
                            </v-img>   
                        </div>
                    </template>
                </base-card>
            </v-col>
        </v-row> -->
    </v-container>
</template>

<script>
import { withAsync } from "../../helpers/withAsync.js";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import {
        getToolBoxServiceGroupList,
        getToolBoxServiceLanguageList,
        getToolBoxServices,
        getSuggestedToolBoxServices,
        getToolBoxServicesByFiltering,
        deleteToolBoxService,
      } from "@/api/adminApi.js"
import { mapGetters } from 'vuex';
import ToolBoxCard from '../../components/ToolBoxCard.vue';
import ConfirmModal from "@/components/ConfirmModal";

export default {
    name: "Toolbox",
    components: {
        ToolBoxCard,
        ConfirmModal,
    },
    data() {
        return {
            getServicesGroupListStatus: apiStatus.Idle,
            getServicesLanguageListStatus: apiStatus.Idle,
            getSuggestedServicesStatus: apiStatus.Idle,
            getAllServicesStatus: apiStatus.Idle,
            getServicesByFilterStatus: apiStatus.Idle,
            deleteServiceStatus: apiStatus.Idle,
            suggestedServices: [],
            selectedGroupType: [],
            selectedServiceType: [],
            selectedLanguage: [],
            page: 1,
            pageCount: 0,
            serviceGroupList: [],
            service_type: ["Video", "PDF", "Web Link", "Image"],
            serviceLanguageList: [],
            services: [],
            openModal: false,
            searchTitle: '',
            resetPage: false,
        };
    },
    computed: {
        ...apiStatusComputed(["getSuggestedServicesStatus", "getAllServicesStatus", "getServicesByFilterStatus", "deleteServiceStatus", "getServicesGroupListStatus", "getServicesLanguageListStatus"]),
        ...mapGetters({
            userIsAdmin: "auth/userIsAdmin",
            serviceID: "toolbox/getServiceID"

        }),
        createToolBox() {
            return { name: "Create - Edit Toolbox" };
        },
    },
    methods: {
        async fetchServicesGroupList() {
            this.getServicesGroupListStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getToolBoxServiceGroupList);

            if (error) {
                this.getServicesGroupListStatus = apiStatus.Error
                return;
            }

            this.getServicesGroupListStatus = apiStatus.Success;
            this.serviceGroupList = response.data;
        },
        async fetchServicesLanguageList() {
            this.getServicesLanguageListStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getToolBoxServiceLanguageList);

            if (error) {
                this.getServicesLanguageListStatus = apiStatus.Error
                return;
            }

            this.getServicesLanguageListStatus = apiStatus.Success;
            this.serviceLanguageList = response.data;
            this.serviceLanguageList = this.serviceLanguageList.map(lang => {
                return {
                    id: lang.id,
                    name: lang.name.charAt(0).toUpperCase() + lang.name.slice(1)
                }
            })
        },
        async fetchSuggestedServices() {
            this.getSuggestedServicesStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getSuggestedToolBoxServices);

            if (error) {
                this.getSuggestedServicesStatus = apiStatus.Error
                return;
            }

            this.getSuggestedServicesStatus = apiStatus.Success;
            this.suggestedServices = response.data;
        },
        async fetchAllServices() {
            this.getAllServicesStatus = apiStatus.Pending;

            let payload = {
                page: this.page,
            }

            const { response, error } = await withAsync(getToolBoxServices, payload);

            if (error) { 
                this.getAllServicesStatus = apiStatus.Error
                return;
            }

            this.getAllServicesStatus = apiStatus.Success;
            this.services = response.data.data;
            this.pageCount = response.data.last_page;
        },
        async fetchServicesByFilter() {
            this.getServicesByFilterStatus = apiStatus.Pending;

            if (this.resetPage == false && this.selectedGroupType.length == 0 && this.selectedServiceType == null && this.selectedLanguage.length == 0) {
                this.page = 1;
            }

            if (!this.resetPage) this.page = 1;

            let payload = {
                page: this.page,
                language: this.selectedLanguage,
                groups: this.selectedGroupType,
                type: this.selectedServiceType,
                title: this.searchTitle
            };

            const { response, error } = await withAsync(getToolBoxServicesByFiltering, payload)
            if (error) {
                this.getServicesByFilterStatus = apiStatus.Error
                return;
            }

            this.services = response.data.data;
            this.pageCount = response.data.last_page;

            if (this.services.length == 0   && this.selectedLanguage.length == 0) this.fetchAllServices();

            this.getServicesByFilterStatus = apiStatus.Success;
            this.resetPage = true;
        },
        deleteService() {
            this.openModal = true;
        },
        async confirmDelete() {
            this.deleteServiceStatus = apiStatus.Pending;

            const { response, error } = await withAsync(deleteToolBoxService, this.serviceID);
        
            if (error) {
                this.deleteServiceStatus = apiStatus.Error
                return;
            }
            
            this.deleteServiceStatus = apiStatus.Success;
            this.openModal = false;
            this.fetchAllServices();
            this.fetchSuggestedServices();
        }
        
    },
    watch: {
        selectedLanguage: {
            handler (newval) {
                console.log("lang", newval);
                if (newval && newval.length != 0) this.resetPage = !this.resetPage;
            },
            deep: true
        },
        selectedGroupType: {
            handler (newval) {
                if (newval && newval != null) this.resetPage = !this.resetPage;
            },
            deep: true
        },
        selectedServiceType: {
            handler (newval) {
                if (newval && newval.length != 0) this.resetPage = !this.resetPage;
            },
            deep: true
        },
        // selectedGroupType(newVal, oldVal) {
        //     this.$router.replace({ query: newVal}, {silent:true})
        // },
        page(newVal) {
            // this.fetchAllServices();
            // this.fetchServicesByFilter();

            if (this.selectedLanguage?.length != 0 || this.selectedGroupType?.length != 0 || this.selectedServiceType?.length != 0 || this.searchTitle != '') {
                if (newVal) {
                    this.fetchServicesByFilter();
                }
            } else {
                this.fetchAllServices();
            }
        },
        searchTitle(newVal, oldVal) {
            if (newVal == "" && oldVal != "") {
                this.fetchServicesByFilter();
            }
        }
    },
    created() {
        window.scrollTo(0, 0);
        this.fetchServicesGroupList();
        this.fetchServicesLanguageList();
        this.fetchSuggestedServices();
        this.fetchAllServices();
    }
};
</script>

<style scoped>
.button-gap {
    gap: 1rem;
}
.button-hover:hover {
    background: #c35272 !important;
    color: #ffffff !important;
}
</style>
