<template>
    <div>
        <v-item-group selected-class="bg-primary">
            <v-container>
                <v-row>
                    <v-col
                     v-for="service in services"
                     :key="service.id"
                     cols="12"
                     md="4"
                    >
                      <v-item>
                            <v-card
                                :class="`pa-3 ${cardBackground == true ? 'card-background-suggested' : 'card-background'}`"
                                height="385"
                                class="card-hover"
                            >
                                <div class="d-flex flex-column">
                                    <div class="d-flex align-center">
                                        <v-icon x-small left :color="`${cardBackground == true ? 'orange' : 'primary'}`">
                                          {{ cardBackground == true ? 'fa fa-star' : 'fa fa-circle-dot' }}
                                        </v-icon>
                                        <h3 class="main-heading text-overflow">
                                            {{ service.title }}
                                        </h3>
                                    </div>
                                    <div class="divider-container mb-5" />
                                    <v-container
                                     v-if="service.service_groups"
                                     class="d-flex flex-wrap"
                                    >
                                    <v-chip-group
                                      center-active
                                      show-arrows
                                    >
                                        <v-chip
                                            v-for="(group, index) in service.service_groups"
                                            :key="index"
                                            class="mb-5 secondary--text"
                                            :color="`${cardBackground == true ? 'purple': 'black'}`"
                                            min-width="50"
                                            max-width="150"
                                        >
                                            <v-icon x-small left>
                                                fa-solid fa-tag
                                            </v-icon>
                                            {{ group.name }}
                                        </v-chip>
                                    </v-chip-group>
                                    </v-container>
                                    <p
                                     v-else
                                     class="py-6"
                                    >
                                       Service Group:
                                       <span class="font-weight-light">Not Provided</span>
                                    </p>
                                    <p class="text-overflow">{{ $t(`Admin.service_box.service_card.serviceType`) }} <span class="font-weight-light">{{ service.content_type || 'Not Provided' }}</span></p>
                                    <p 
                                    v-if="service.languages"
                                    class="text-overflow"
                                    >
                                    {{ $t(`Admin.service_box.service_card.languages`) }}
                                        <span
                                         v-for="lang in service.languages"
                                         :key="lang.id"
                                         class="font-weight-light ml-1"
                                        >
                                         {{ lang.name.charAt(0).toUpperCase() + lang.name.slice(1) }}
                                        </span>
                                    </p>
                                    <p v-else class="text-overflow">
                                        {{ $t(`Admin.service_box.service_card.languages`) }}
                                        <span class="font-weight-light ml-1">Not Provided</span>
                                    </p>
                                    <p>{{ $t(`Admin.service_box.service_card.proficiency`) }} <span :class="`font-weight-light ${proficiencyColor(service.proficiency_level)}--text`">{{ service.proficiency_level || 'Not Provided'}}</span></p>
                               </div>
                               <v-spacer class="mt-10"></v-spacer>
                               <div class="d-flex">
                                 <v-btn
                                 v-if="userIsAdmin"
                                 depressed
                                 outlined
                                 color="primary"
                                 class="button-hover" 
                                 @click="editService(service.id)"
                                >
                                 <v-icon left x-small>fa fa-edit</v-icon>
                                 Edit
                                </v-btn>
                                <v-icon 
                                 v-if="userIsAdmin"
                                 right
                                 small
                                 color="error"
                                 class="delete-button"
                                 @click="confirmDelete(service.id)"
                                >
                                  fa fa-trash
                                 </v-icon>
                                <v-spacer></v-spacer>
                                <v-btn
                                 depressed
                                 outlined
                                 color="black"
                                 class="button-hover"
                                 @click="showServiceDetails(service.id)"
                                >
                                 <v-icon left x-small>fa fa-circle-info</v-icon>
                                 Details
                                </v-btn>
                               </div>
                               <!-- </div> -->
                            </v-card>
                          </v-item>
                    </v-col>
                </v-row>
            </v-container>
        </v-item-group>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';



export default {
    name: 'ToolBoxCard',
    props: {
        services: {
            type: [Array, Object],
            required: false,
            default: () => {}
        },
        cardBackground: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters({
            userIsAdmin: "auth/userIsAdmin",
        }),
    },
    methods: {
        editService(id) {
            this.$router.push({name: 'Create - Edit Toolbox', params: {id: id} });
        },
        showServiceDetails(id) {
            this.$router.push({name: 'ToolBox Card Details', params: {id: id} });
        },
        confirmDelete(id) {
            this.$store.dispatch("toolbox/setServiceID", id)
            this.$emit('delete-service', id)
        },
        proficiencyColor(name) {
            if(name == 'Beginner') return 'primary'
            if(name == 'Intermediate') return 'orange'
            if(name == 'Advanced') return 'error'
        }
    }
};
</script>

<style scoped>
.divider-container {
    width: 20%;
    border-bottom: 2px solid #33CC33 !important;
}
.card-hover:hover .divider-container {
    width: 15%;
    transition: 0.1s ease-in;
}
.card-background-suggested {
    /* border: 1px solid;
    border-image-slice: 1;
    background: #fff;
    border-image-source: linear-gradient(to left, #3b4c55, #ffffff);
    box-shadow: rgba(0, 0, 0, 0.01) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
} 
.card-background {
    box-shadow: rgba(0, 0, 0, 0.01) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
    background-image: linear-gradient(to top, #ffffff 0%, #eeeeeecf 100%);
}
.card-background:hover {
    background-image: linear-gradient(to top, #cfe7cd82 0%, #eeeeeecf 100%);
    transition: 0.1s ease-in-out;
    transform: scale(1.01);
}
.card-background-suggested:hover {
    transition: 0.2s ease-in-out;
    transform: scale(1.02);
    border-image-source: linear-gradient(to right, #33CC33, #ffffff);
}
.button-hover:hover {
    background: #33CC33 !important;
    color: #fff  !important;
    transition: 0.1s ease-in;
}
.delete-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1.7rem;
}
.text-overflow {
    width: 80%;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    }
</style>
